import { addData, getCountryCityCode, logoutUser } from '@/actions/auth';
import { clearCourseData } from '@/actions/courses';
import { clearHomeDropdown, getHomeSearch } from '@/actions/home';
import { getNotifications } from '@/actions/notifications';
import { getCourseWishlist } from '@/actions/profile';
import UserBar from '@/components/landing/account/ProfileDropdown';
import {
  capitalizeFirstLetter,
  SocialMediaIcons,
  socialMediaImages,
} from '@/helpers/dataHelper';
import { courseConverter, courseConverterNew } from '@/helpers/dropdownHelper';
import { isLogin } from '@/helpers/loginHelper';
import useWidth, { IBooleanArgs } from '@/helpers/widthUtil';
import { CloseOutlined, HeartOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Badge,
  Button,
  Drawer,
  Input,
  Menu,
  MenuProps,
  Select,
} from 'antd';
import classnames from 'classnames';
import Link from 'next/link';
import Router, { NextRouter, useRouter } from 'next/router';
import React, {
  FC,
  FormEvent,
  KeyboardEvent,
  memo,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import AvatarComponent from '../../common/avatar';
import Notification from '../notification/Notification';
import NavbarLogo from './NavbarLogo';
import Image from 'next/image';

type NavbarProps = { isCourseDetailPage?: boolean; IsDashboard?: boolean };

const Navbar: FC<NavbarProps> = () => {
  const { isGalaxyZFold }: IBooleanArgs = useWidth();
  const router: NextRouter = useRouter();
  const dispatch: Dispatch = useDispatch();

  const query = { page: 1, per_page: 10 };
  const tab = router.pathname;
  const { SubMenu } = Menu;

  const [auth, setAuth] = useState<boolean>(true);
  const [queryValue, setQueryValue] = useState<string>('');

  const { user, notification, courseData, wishlist, user_type, currency } =
    useSelector((state: RootStateOrAny) => ({
      user: state.auth?.creds?.user_data,
      notification: state?.notificationReducer?.notification,
      courseData: state?.home?.courseData,
      wishlist: state.profile.wishlist,
      user_type: state?.auth?.creds?.user_data?.user_type,
      currency: state?.auth?.currency,
    }));

  const { data: courseDropDown } = courseData;
  const unreadCount: number = notification?.unreadCount;
  const [showbadgeCount, setshowBadgeCount] = useState<boolean>(false);
  const [bCount, setBCount] = useState<number>(null);
  const [value, setValue] = useState<string>('');
  const [options, setOptions] = useState<object[]>([]);
  const [showCurrencyPicker, setShowCurrencyPicker] = useState<boolean>(false);

  //To check userType is partner or not
  let token: string;

  useEffect(() => {
    const addressBasedCurrency = window.localStorage.getItem('currency');
    dispatch(clearCourseData());
    isLogin() && tab !== '/profile/wishlist' && dispatch(getCourseWishlist());
    if (!addressBasedCurrency) {
      dispatch(getCountryCityCode());
    }
    return () => {
      setValue('');
      dispatch(clearHomeDropdown());
    };
  }, []);

  useEffect(() => {
    if (router?.query?.name?.length > 0) {
      const getData = setTimeout(() => {
        dispatch(getHomeSearch({ name: router?.query?.name }));
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [router?.query]);

  useEffect(() => {
    setOptions(courseConverter(courseDropDown));
  }, [courseDropDown]);

  try {
    if (typeof window !== 'undefined') {
      let elementId: HTMLElement = document.getElementById('navbar');
      document.addEventListener('scroll', () => {
        if (typeof window !== undefined) {
          if (window.scrollY > 170) {
            if (elementId !== null) {
              elementId.classList.add('is-sticky');
            }
          } else {
            if (elementId !== null) {
              elementId.classList.remove('is-sticky');
            }
          }
        }
      });
      // typeof window !== undefined && window.scrollTo(0, 0);
    }
  } catch (err) {}

  useEffect(() => {
    token = localStorage.getItem('token');
    // dispatch(clearNotificationState())
    if (token && notification?.data?.length === 0) {
      dispatch(getNotifications(query));
    }
  }, []);

  useEffect(() => {
    if (!token) {
      setAuth(false);
    }
    if (window.location.pathname.includes('/course')) {
      setShowCurrencyPicker(true);
    }
  }, []);

  useEffect(() => {
    if (queryValue?.length >= 1) {
      const getData = setTimeout(() => {
        dispatch(getHomeSearch({ name: queryValue }));
      }, 1000);

      return () => clearTimeout(getData);
    }
  }, [queryValue]);

  const handleBadgeCount = (): void => {
    setshowBadgeCount(true);
  };

  const handleBCount = (count: number): void => {
    setBCount(count);
  };

  const NotificationDropdownMenu: FC<MenuProps> = () => (
    <Menu
      mode="horizontal"
      className="landing-notification text-center"
      triggerSubMenuAction={'click'}
      disabledOverflow
      style={{ width: '60px' }}
    >
      <SubMenu
        className="notifications-submenu"
        key="notification"
        title={
          <Badge
            count={
              showbadgeCount
                ? bCount < 0
                  ? ''
                  : bCount
                : unreadCount < 0
                ? ''
                : unreadCount
            }
          >
            <span className="submenu-title-wrapper">
              <img
                src="/images/notification.svg"
                style={{ width: '20px', height: '21.54px' }}
              />
            </span>
          </Badge>
        }
      >
        <Notification
          handleBadgeCount={handleBadgeCount}
          handleBCount={handleBCount}
        />
      </SubMenu>
    </Menu>
  );

  const onGlobalSearch = (term: string): void => {
    const currentQ = router?.query;
    if (currentQ?.name) {
      currentQ.name = term;
    }

    setQueryValue(term);
    if (term?.length === 0 || value?.length === 0) {
      dispatch(clearHomeDropdown());
    }
    // if (term?.length >= 1) {
    //   dispatch(getHomeSearch({ name: term }));
    // }
  };

  const onInputSearch = (e: KeyboardEvent<HTMLInputElement>): void => {
    const extraQueries: object = {
      course_type: router?.query?.course_type || '',
      name: (e.target as HTMLInputElement).value || '',
      test: router?.query?.test || '',
      sort: router?.query?.sort || '',
    };
    if (e.key === 'Enter') {
      Router.push({
        pathname: `/course`,
        query: { ...extraQueries },
      });
    }
  };

  const onCourseSearch = (e: FormEvent): void => {
    e.preventDefault();
    Router.push({
      pathname: `/course`,
      query: { name: queryValue },
    });
  };

  const handleLogout = async (e: MouseEvent<HTMLAnchorElement>): Promise<void> => {
    e.preventDefault();
    if (typeof window !== undefined) {
      dispatch(logoutUser());
    }
  };

  const [navDrawer, setNavDrawer] = useState<boolean>(false);
  const [searchDrawer, setSearchDrawer] = useState<boolean>(false);

  return (
    <>
      {/* Mobile Navbar */}
      <div className="d-sm-block d-md-block d-lg-none">
        {/* <MobileNavbar queryValue={queryValue} onGlobalSearch={onGlobalSearch} /> */}
        <div className="position-relative" style={{ zIndex: '1001 !important' }}>
          <Drawer
            className="nav-drawer"
            placement={'left'}
            title={
              <>
                <div className="d-flex align-items-center justify-content-between">
                  {auth ? (
                    <div
                      className="d-flex flex-column"
                      onClick={() => {
                        router.push('/profile/settings');
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <AvatarComponent
                          avatar={user?.avatar}
                          firstName={user?.first_name}
                          lastName={user?.last_name}
                          // size={55}
                        />
                        <div className=" ml-2 mt-auto d-flex flex-column text-black font-size-sm">
                          {`${capitalizeFirstLetter(
                            user?.first_name
                          )} ${capitalizeFirstLetter(user?.last_name)}`}
                          <span className="dropdown-profile-email d-block">
                            {user?.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-row flex-nowrap justify-content-between">
                      <Link href="/login" prefetch={false}>
                        <a>
                          <Button
                            size={'middle'}
                            // icon={<LoginOutlined rev="" className="float-right mt-1 ml-1" />}
                            className="btn-outline-danger border-danger pl-3 pr-3 montserrat-font-family"
                          >
                            Log in
                          </Button>
                        </a>
                      </Link>
                      <Link href="/signup" prefetch={false}>
                        <a>
                          <Button
                            size={'middle'}
                            className="btn-danger text-white border-danger pl-3 pr-3 montserrat-font-family ml-2"
                          >
                            Sign up
                          </Button>
                        </a>
                      </Link>
                    </div>
                  )}

                  <CloseOutlined
                    rev=""
                    style={{ fontSize: '24px' }}
                    className="text-danger float-right cursor-pointer"
                    onClick={() => setNavDrawer(false)}
                  />
                </div>
              </>
            }
            closable={false}
            onClose={() => setNavDrawer(false)}
            visible={navDrawer}
            width={isGalaxyZFold ? '80%' : ('70%' as any)}
            maskClosable={true}
            style={{ overflow: 'scroll' }}
          >
            <ul className="navbar-nav p-2">
              {/* <li className="nav-item p-2">
                <h6 className="text-primary font-weight-bold">
                  <u>Menu</u>
                </h6>
              </li> */}
              <li className="nav-item p-2">
                <Link href="/profile/dashboard" prefetch={false}>
                  <a className={tab === '/profile/dashboard' ? 'text-danger' : ''}>
                    Profile
                  </a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link href="/notifications" prefetch={false}>
                  <a
                    className={
                      tab === '/notifications'
                        ? 'text-danger d-flex justify-content-between'
                        : 'd-flex justify-content-between'
                    }
                  >
                    Notifications
                    <Badge
                      className="p-0"
                      count={
                        showbadgeCount
                          ? bCount < 0
                            ? ''
                            : bCount
                          : unreadCount < 0
                          ? ''
                          : unreadCount
                      }
                    ></Badge>
                  </a>
                </Link>
              </li>

              <li className="nav-item p-2">
                <Link href="/profile/my-course" prefetch={false}>
                  <a className="nav-link p-0">My Courses</a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link href="/profile/wishlist" prefetch={false}>
                  <a className="nav-link p-0">Wishlist</a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link href="/room" prefetch={false}>
                  <a className="nav-link p-0">Rooms</a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link href="/class-list" prefetch={false}>
                  <a className="nav-link p-0">Live class</a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link href="/mock-test" prefetch={false}>
                  <a className="nav-link p-0">Mock Test</a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link href="/notes" prefetch={false}>
                  <a className="nav-link p-0">Notes</a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link href="/profile/my-attempted-tests" prefetch={false}>
                  <a className="nav-link p-0">Attempted Tests</a>
                </Link>
              </li>

              <li className="nav-item p-2">
                <Link href="/purchase-history" prefetch={false}>
                  <a className="nav-link p-0">Purchase History</a>
                </Link>
              </li>
              <li className="nav-item p-2">
                <a onClick={(e) => handleLogout(e)} className="nav-link p-0">
                  Log out
                </a>
              </li>
            </ul>
            {!isGalaxyZFold && (
              <div
                className="bg-primary position-absolute w-100"
                style={{ bottom: 0 }}
              >
                <span className="text-center pt-2">
                  <h6 className="text-white pt-2 pb-2">Follow us on:</h6>
                </span>
                <ul className="navbar-nav d-flex flex-row justify-content-between border-bottom p-2 ">
                  {socialMediaImages?.map((each, key) => (
                    <li className="nav-item pb-2 pl-2 pr-2" key={key}>
                      <Link href={each?.url}>
                        <a target="__blank" title={each?.title}>
                          <SocialMediaIcons src={each?.image} key={each?.id} />
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Drawer>
          <Drawer
            placement="left"
            className="search-drawer"
            // onClose={() => setSearchShow(false)}
            closable={false}
            maskClosable={false}
            visible={searchDrawer}
            width={'100%' as any}
            style={{ overflow: 'scroll' }}
            title={
              <div>
                <form onSubmit={onCourseSearch} className="d-flex">
                  <div className="d-flex p-1 justify-content-center align-items-center">
                    <SearchOutlined
                      rev=""
                      style={{ fontSize: '24px' }}
                      className="text-dark"
                      onClick={(e) => {
                        Router.push({
                          pathname: `/course`,
                          query: { name: router?.query?.name || queryValue },
                        });
                      }}
                    />
                  </div>
                  <Input
                    className="float-left border-0"
                    size="large"
                    title="Search Courses"
                    placeholder="What do you want to learn?"
                    value={router?.query?.name || queryValue}
                    onChange={(e) => onGlobalSearch(e.target.value)}
                  />
                  <CloseOutlined
                    rev=""
                    style={{ fontSize: '24px' }}
                    className="text-dark float-right cursor-pointer mt-1"
                    onClick={() => setSearchDrawer(false)}
                  />
                </form>
              </div>
            }
          >
            {courseConverterNew(courseDropDown)}
          </Drawer>

          <div className={`navbar-area navbar-white`}>
            <div className="learnsic-nav">
              <div className="container">
                <section className="header-main bg-white">
                  <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-3">
                      <div className="my-auto d-flex align-items-center">
                        <SearchOutlined
                          rev=""
                          onClick={(e) => {
                            setSearchDrawer(true);
                            // document.querySelector(".mobile-search-global")
                          }}
                          style={{ fontSize: '20px' }}
                          className="text-dark mobile-search-icon ml-2 cursor-pointer"
                        />{' '}
                        {showCurrencyPicker && (
                          <Select
                            style={{ minWidth: '100px' }}
                            bordered={false}
                            className={auth ? 'mt-2' : ''}
                            getPopupContainer={(node) => node.parentNode}
                            value={currency === 'NPR' ? 'NPR' : 'USD'}
                            onChange={(e) => {
                              window.localStorage.setItem('currency', e);
                              dispatch(addData({ key: 'currency', value: e }));
                              window.location.reload();
                            }}
                          >
                            {' '}
                            <Select.Option value="" key="" disabled>
                              Currency
                            </Select.Option>
                            <Select.Option value="NPR" key="NPR">
                              <Image
                                src="/images/flags/np.svg"
                                height="18"
                                width="18"
                                alt="NPR"
                              />{' '}
                              NPR
                            </Select.Option>
                            <Select.Option value="USD" key="USD">
                              <Image
                                src="/images/flags/us.svg"
                                height="16"
                                width="16"
                                alt="USD"
                              />{' '}
                              USD
                            </Select.Option>
                          </Select>
                        )}
                      </div>
                    </div>

                    <div className="col-5 d-flex justify-content-center text-center pt-2">
                      <Link href="/" prefetch={false}>
                        <a className="navbar-brand cursor-pointer">
                          <NavbarLogo />
                        </a>
                      </Link>
                    </div>

                    <div className="col-2 d-flex justify-content-end">
                      {auth ? (
                        <button
                          onClick={() => setNavDrawer(true)}
                          className={classnames(
                            'navbar-toggler navbar-toggler-right collapsed mr-1 border-0 cursor-pointer',
                            'text-danger'
                          )}
                          type="button"
                          data-toggle="collapse"
                          data-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span
                            className="icon-bar top-bar"
                            style={{ background: '#DE2229' }}
                          ></span>
                          <span
                            className="icon-bar middle-bar"
                            style={{ background: '#DE2229' }}
                          ></span>
                          <span
                            className="icon-bar bottom-bar"
                            style={{ background: '#DE2229' }}
                          ></span>
                        </button>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <Link href="/login" prefetch={false}>
                            <a>
                              <Button
                                size={'middle'}
                                // icon={<LoginOutlined rev="" className="float-right mt-1 ml-1" />}
                                className="cursor-pointer btn-outline-danger border-danger montserrat-font-family"
                              >
                                Log in
                              </Button>
                            </a>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Navbar Ends */}

      <div id="navbar" className="navbar-area navbar-white d-none d-lg-block">
        <div className="learnsic-nav">
          <div
            className={
              // IsDashboard ? 'container-xxl' :
              'custom-container'
            }
          >
            <section className="header-main bg-white">
              <div className="row p-2 pt-3 pb-3 d-flex align-items-center">
                <div className="col-md-2">
                  <Link href="/" prefetch={false}>
                    <a className="navbar-brand">
                      <div className="img-fluid mt-auto d-block">
                        <NavbarLogo />
                      </div>
                    </a>
                  </Link>
                </div>
                <div className="col-md-6">
                  <div className="d-flex mx-auto">
                    <AutoComplete
                      getPopupContainer={(trigger) => trigger.parentElement}
                      style={{ width: '95%' }}
                      options={options}
                      className="border-14 border-muted global-search-website mx-auto"
                      onChange={onGlobalSearch}
                      showSearch
                      value={router?.query?.name || queryValue}
                    >
                      <Input.Search
                        className="float-left border-0"
                        onKeyPress={(e) => onInputSearch(e)}
                        size="large"
                        title="Search Courses"
                        enterButton
                        placeholder="What do you want to learn?"
                        onSearch={(e) => {
                          Router.push({
                            pathname: `/course`,
                            query: { name: e },
                          });
                        }}
                      />
                    </AutoComplete>
                  </div>
                </div>

                <div className="col-md-4 justify-content-between d-flex">
                  {user_type !== 'partner' && (
                    <Link href="/tutor/" prefetch={false}>
                      <a
                        href="/tutor/"
                        className={
                          tab === '/tutor'
                            ? 'text-secondary my-auto font-size-sm font-weight-bold'
                            : 'text-dark my-auto font-size-sm font-weight-500'
                        }
                      >
                        Teach at Learnsic
                      </a>
                    </Link>
                  )}
                  {showCurrencyPicker && (
                    <Select
                      style={{ minWidth: '100px' }}
                      bordered={false}
                      className={auth ? 'mt-2' : ''}
                      getPopupContainer={(node) => node.parentNode}
                      value={currency === 'NPR' ? 'NPR' : 'USD'}
                      onChange={(e) => {
                        window.localStorage.setItem('currency', e);
                        dispatch(addData({ key: 'currency', value: e }));
                        window.location.reload();
                      }}
                    >
                      {' '}
                      <Select.Option value="" key="" disabled>
                        Currency
                      </Select.Option>
                      <Select.Option value="NPR" key="NPR">
                        <Image
                          src="/images/flags/np.svg"
                          height="20"
                          width="20"
                          alt="NPR"
                          className="pt-2"
                        />
                        NPR
                      </Select.Option>
                      <Select.Option value="USD" key="USD">
                        <Image
                          src="/images/flags/us.svg"
                          height="18"
                          width="18"
                          alt="USD"
                          className="pt-2"
                        />{' '}
                        USD
                      </Select.Option>
                    </Select>
                  )}
                  {auth && (
                    <Link href="/profile/my-course" prefetch={false}>
                      <a
                        className={
                          tab === '/profile/my-course'
                            ? 'text-secondary my-auto font-size-sm font-weight-bold'
                            : 'text-dark my-auto font-size-sm font-size-sm font-weight-500'
                        }
                      >
                        My Courses
                      </a>
                    </Link>
                  )}

                  <div className="others-options d-none d-lg-block">
                    {auth ? (
                      <>
                        <div className="login-btn d-flex">
                          {wishlist?.meta?.total > 0 && (
                            <div className={`wishlist-icon `}>
                              <Badge count={wishlist?.meta?.total} className="mt-2">
                                <span className="submenu-title-wrapper">
                                  <HeartOutlined
                                    rev=""
                                    className={'text-dark cursor-pointer pr-1'}
                                    style={{ fontSize: '22px' }}
                                    title="Wishlist"
                                    onClick={() => router.push('/profile/wishlist')}
                                  />
                                </span>
                              </Badge>
                            </div>
                          )}
                          {/* <PermissionHandler
                            permission={permission?.view_notification}
                          >
                            <NotificationDropdownMenu />
                          </PermissionHandler> */}
                          <NotificationDropdownMenu />
                          <UserBar />
                        </div>
                      </>
                    ) : (
                      <div className="d-flex flex-row flex-nowrap justify-content-between">
                        <Link href="/login" prefetch={false}>
                          <a>
                            <Button
                              size={'middle'}
                              className="btn-outline-danger border-danger pl-3 pr-3 montserrat-font-family"
                              style={{ border: '2px solid #de2229' }}
                            >
                              Log in
                            </Button>
                          </a>
                        </Link>

                        <Link href="/signup" prefetch={false}>
                          <a>
                            <Button
                              size={'middle'}
                              className="btn-danger text-white border-danger pl-3 pr-3 montserrat-font-family ml-2"
                            >
                              Sign up
                            </Button>
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Navbar);
